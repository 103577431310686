import React, { useState, useCallback, useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import VerificationModal from '../../molecules/VerificationModal';
import InputWithLabel from '../InputWithLabel';
import useSignup from '../../apiHooks/auth/useSignup';
import useLogin from '../../apiHooks/auth/useLogin';
import useFetchPartner from '../../apiHooks/auth/useFetchPartner';
import { getUrlParam } from '../../utils/url';
import { getErrorMessageFromResponse } from '../../utils/error';

import './login-signup-modal.scss';
import { Helmet } from 'react-helmet';

import ReactHtmlParser from 'react-html-parser';
import useFetchSignupOptions from '../../apiHooks/auth/useFetchSignupOptions';
import { SignupOption } from '../../types';
import { selectPartnerProfile } from '../../apiHooks/partnerProfile';
import { LoginSignUpButtons } from '../../styling/buttons';
import { LinearProgress } from '@mui/material';
import { removeWhiteSpace } from '../../utils/text';

const orgBaseUrl = process.env.REACT_APP_ORG_URL;

interface Props {
  mode: 'signup' | 'login';
  className?: string;
}

const LoginSignupModal = ({ mode, className }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { signup, isLoadingSignup } = useSignup();
  const { login, isLoadingLogin, loginError } = useLogin();

  let partnerId = parseInt(getUrlParam('id') as string);

  const partnerProfile = useSelector(selectPartnerProfile);

  const { fetchPartnerResponseData, isLoadingPartner } = useFetchPartner({
    id: partnerId,
    setCookies: true,
  });

  const { pathname } = useLocation();

  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [cookiePolicy, setCookiePolicy] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] =
    useState<boolean>(false);
  const [selectedMode, setSelectedMode] = useState<'signup' | 'login'>(mode);
  const [signupOptionsState, setSignupOptionsState] = useState<number[]>([]);

  const {
    name: partnerName,
    gdpr_permission_required: askForGDPRPermission,
    custom_gdpr_text,
    custom_gdpr_explainer,
    custom_terms_text,
    custom_terms_explainer,
    subdomain,
    organisation_title,
    volunteer_title,
  } = partnerProfile || fetchPartnerResponseData || {};

  const { signupOptions, hasFinishedLoadingSignupOptions } =
    useFetchSignupOptions(isNaN(partnerId) ? null : partnerId);

  const [GDPRPermissionGiven, setGDPRPermissionGiven] = useState(false);
  const [customTermsConsented, setCustomTermsConsented] = useState(false);

  const switchMode = () => {
    selectedMode === 'login'
      ? navigate({
          pathname: '/signup',
          search: createSearchParams(searchParams).toString(),
        })
      : navigate({
          pathname: '/login',
          search: createSearchParams(searchParams).toString(),
        });
  };

  const onSignup = useCallback(
    async (
      email: string,
      password: string,
      confirmPassword: string,
      partnerId?: number,
      signup_options?: number[] | null
    ) => {
      setErrorMessage(null);
      try {
        if (password !== confirmPassword) {
          throw new Error('Passwords do not match.');
        }
        return signup({ email, password, partnerId, signup_options });
      } catch (err) {
        setErrorMessage(getErrorMessageFromResponse(err));
        throw err;
      }
    },
    [signup]
  );

  const onLogin = useCallback(
    async (email: string, password: string) => {
      setErrorMessage(null);
      try {
        await login({ email, password });
      } catch (err) {
        setErrorMessage(getErrorMessageFromResponse(err));
      }
    },
    [login]
  );

  const onCTAClick = useCallback(async () => {
    if (selectedMode === 'signup') {
      try {
        const signup_options = [...signupOptionsState];
        await onSignup(
          removeWhiteSpace(email),
          password,
          confirmPassword,
          partnerProfile?.id || partnerId,
          signup_options
        );
        return setVerificationModalOpen(true);
      } catch (err) {
        setErrorMessage(getErrorMessageFromResponse(err));
      }
    } else {
      onLogin(removeWhiteSpace(email), password);
    }
  }, [
    selectedMode,
    onLogin,
    email,
    password,
    onSignup,
    confirmPassword,
    partnerId,
    signupOptionsState,
    partnerProfile,
  ]);

  const handleKeydown = useCallback(
    (event: React.KeyboardEvent) => {
      if (selectedMode === 'login') {
        if (event.key === 'Enter' && !!email) {
          onCTAClick();
        }
      }
      if (selectedMode === 'login') {
        if (
          event.key === 'Enter' &&
          !!email &&
          !!password &&
          !!confirmPassword
        ) {
          onCTAClick();
        }
      }
    },
    [confirmPassword, email, onCTAClick, password, selectedMode]
  );

  const onConfirmPasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setConfirmPassword(newValue);
  };

  useEffect(() => {
    if (selectedMode === 'signup' && !!fetchPartnerResponseData) {
      ReactTooltip.rebuild(); // react tool-tip needs this for conditional renders
    }
  }, [fetchPartnerResponseData, selectedMode]);

  useEffect(() => {
    if (pathname === '/login') setSelectedMode('login');
    if (pathname === '/signup') setSelectedMode('signup');
  }, [pathname]);

  const welcomeText = selectedMode === 'signup' ? 'Welcome!' : 'Welcome back!';
  const vol_title =
    !volunteer_title || volunteer_title === ''
      ? 'Volunteer Login'
      : `${volunteer_title} Login`;

  const primaryOptionTitle =
    selectedMode === 'signup' ? 'Create an account' : vol_title;
  const secondaryOptionTitle =
    selectedMode === 'signup'
      ? 'Already have an account?'
      : "Don't have an account?";
  const CTAButtonText =
    selectedMode === 'signup' ? 'Create My Account' : 'Login';
  const secondaryOptionButtonText =
    selectedMode === 'signup' ? 'Login' : 'Signup';

  const generalButtonDisabledRules = !email || !password;
  const signupButtonDisabledRules =
    !cookiePolicy ||
    !confirmPassword ||
    (askForGDPRPermission && !GDPRPermissionGiven) ||
    (!!custom_terms_text && !customTermsConsented);

  const requiredOptionsMissing = signupOptions.some(
    (item) =>
      item.is_required === true &&
      !signupOptionsState.some((i) => i === item.id)
  );

  const buttonDisabledRules =
    generalButtonDisabledRules ||
    (selectedMode === 'signup' && signupButtonDisabledRules) ||
    (selectedMode === 'signup' && requiredOptionsMissing);

  let titleTag = '';
  let metaDescription = '';
  if (selectedMode === 'login') {
    titleTag =
      partnerName === undefined
        ? 'Digital Boost Volunteer Portal | Log in to your account'
        : 'Digital Boost Volunteer Portal | Log in - ' + partnerName;

    metaDescription =
      partnerName === undefined
        ? 'Log in to your Digital Boost Volunteer Portal'
        : partnerName + ' - Log in to your Digital Boost Volunteer Portal';
  } else {
    titleTag =
      partnerName === undefined
        ? 'Digital Boost Volunteer Portal | Sign Up'
        : 'Digital Boost Volunteer Portal | Sign Up - ' + partnerName;

    metaDescription =
      partnerName === undefined
        ? 'Welcome to Digital Boost! Sign up to be a volunteer business mentor and help support small businesses and charities'
        : 'Welcome to Digital Boost! Sign up to be a volunteer business mentor through ' +
          partnerName +
          "'s referral and help support small businesses and charities";
  }

  if (!hasFinishedLoadingSignupOptions) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>{titleTag}</title>
        <meta name="description" content={metaDescription}></meta>
      </Helmet>
      {verificationModalOpen && <VerificationModal email={email} />}
      <ReactTooltip place={'top'} />

      <div className={`login-signup__container ${className ? className : ''}`}>
        <div
          className="login-signup__primary-container"
          data-cy="sign-up-container"
        >
          <h1 className="login-signup__welcome-text">{welcomeText}</h1>
          <h2>{primaryOptionTitle}</h2>

          {(loginError || errorMessage) && (
            <div className="error">{loginError || errorMessage}</div>
          )}
          <InputWithLabel
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value?.trim());
            }}
            type={'text'}
            label={'Email'}
            onKeyDown={handleKeydown}
          />
          <InputWithLabel
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(e.target.value);
            }}
            type={'password'}
            label={'Password'}
            onKeyDown={handleKeydown}
          />
          {selectedMode === 'signup' && (
            <InputWithLabel
              type={'password'}
              label={'Confirm password'}
              onInput={onConfirmPasswordInput}
              placeHolder={'Confirm your password'}
              onKeyDown={handleKeydown}
            />
          )}
          {selectedMode === 'signup' && (
            <div className="login-signup__terms-list">
              <div
                className={`login-signup__terms-container`}
                data-cy="cookie-check-box"
                onClick={() => setCookiePolicy(cookiePolicy ? false : true)}
              >
                <label>
                  <input
                    name="cookie"
                    type="checkbox"
                    checked={cookiePolicy}
                    className="login-signup__terms-checkbox"
                  />
                  <label>
                    I agree to the Digital Boost{' '}
                    <a
                      href="https://www.digitalboost.org.uk/legals/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      User Agreement
                    </a>
                    ,{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.digitalboost.org.uk/legals/privacy-policy"
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.digitalboost.org.uk/legals/cookiepolicy"
                    >
                      Cookie Policy
                    </a>
                  </label>
                </label>
              </div>
              {askForGDPRPermission && (
                <div
                  onClick={() => setGDPRPermissionGiven(!GDPRPermissionGiven)}
                  className="login-signup__terms-container"
                >
                  <input
                    checked={GDPRPermissionGiven}
                    type={'checkbox'}
                    readOnly={true}
                    className="login-signup__terms-checkbox"
                  />
                  <label>
                    {' '}
                    {custom_gdpr_text ? (
                      <>{ReactHtmlParser(custom_gdpr_text)}</>
                    ) : (
                      <>
                        I agree to share my performance analytics with{' '}
                        <b>{partnerName} </b>
                      </>
                    )}{' '}
                    (
                    <span
                      className="login-signup__data-tip"
                      data-tip={
                        custom_gdpr_explainer
                          ? custom_gdpr_explainer
                          : `If you click this button, you agree that we can share with ${partnerName} how many people you mentored and how many hours you mentored them. You are free at any time to update your preferences if you no longer wish us to share this information with ${partnerName}`
                      }
                    >
                      What is shared and why?
                    </span>
                    )
                  </label>
                </div>
              )}
              {custom_terms_text && (
                <div
                  onClick={() => setCustomTermsConsented(!customTermsConsented)}
                  className="login-signup__terms-container"
                >
                  <input
                    checked={customTermsConsented}
                    type={'checkbox'}
                    readOnly={true}
                    className="login-signup__terms-checkbox"
                  />
                  <label>
                    {' '}
                    {ReactHtmlParser(custom_terms_text)}{' '}
                    {custom_terms_explainer && '('}
                    {custom_terms_explainer && (
                      <span
                        className="login-signup__data-tip"
                        data-tip={custom_terms_explainer}
                      >
                        Read more
                      </span>
                    )}
                    {custom_terms_explainer && ')'}
                  </label>
                </div>
              )}

              {signupOptions.map((item: SignupOption) => {
                const checked = signupOptionsState.some((i) => i === item.id);

                const addOptionId = (optionId: number) => {
                  let newOptionList: number[] = [...signupOptionsState];

                  if (signupOptionsState.includes(optionId)) {
                    const index = newOptionList.indexOf(optionId);
                    newOptionList.splice(index, 1);
                  } else {
                    newOptionList.push(optionId);
                  }

                  setSignupOptionsState(newOptionList);
                };

                return (
                  <div
                    onClick={() => addOptionId(item.id)}
                    className="login-signup__terms-container"
                  >
                    <input
                      checked={checked}
                      type={'checkbox'}
                      readOnly={true}
                      className="login-signup__terms-checkbox"
                    />
                    <label>
                      {' '}
                      {ReactHtmlParser(item?.text)} {item?.explainer && '('}
                      {item?.explainer && (
                        <span
                          className="login-signup__data-tip"
                          data-tip={item?.explainer}
                        >
                          Read more
                        </span>
                      )}
                      {item?.explainer && ')'}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
          {selectedMode === 'login' && (
            <div className="login-signup__forgot-password-container">
              <a
                href="/resetpassword"
                className="login-signup__forgot-password"
              >
                Forgotten your password?
              </a>
            </div>
          )}
          <div className="login-signup__cta-button-container">
            {isLoadingSignup || isLoadingLogin ? (
              <LinearProgress color="success" />
            ) : (
              <LoginSignUpButtons
                variant="outlined"
                sx={{ width: '250px', marginTop: '10px', marginBottom: '20px' }}
                onClick={onCTAClick}
                disabled={buttonDisabledRules}
              >
                {CTAButtonText}
              </LoginSignUpButtons>
            )}
            {selectedMode === 'login' && (
              <div className="login-signup__org-login-container">
                <a
                  href={
                    subdomain
                      ? `https://${subdomain}.${orgBaseUrl?.replace(
                          'https://',
                          ''
                        )}login`
                      : `${orgBaseUrl}login`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="login-signup__org-login"
                >
                  {!isLoadingPartner && organisation_title
                    ? `Switch to ${organisation_title} Log In`
                    : !isLoadingPartner && !organisation_title
                    ? 'Switch to mentee Log In'
                    : null}
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="login-signup__secondary-container">
          <h3>{secondaryOptionTitle}</h3>
          <LoginSignUpButtons
            variant="outlined"
            sx={{ width: '90%', alignSelf: 'center' }}
            onClick={switchMode}
          >
            {secondaryOptionButtonText}
          </LoginSignUpButtons>
        </div>
      </div>
    </>
  );
};

export default LoginSignupModal;
