import { Button } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import './style.scss';
import MenteeDetailsModal from './menteeDetailsModal';
import { BoostCallEvent } from '../../types';
import MessagesModal from '../../components/MessagesModal';
import RescheduleUpcomingCard from '../../components/RescheduleUpcomingCard';
import { formatDate } from '../../utils/time';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import RescheduleDialog from '../../components/Dashboard/NewRescheduleCard/rescheduleDialog';
import RescheduleRequestByOrganisation from './rescheduleRequestByOrganisation';
import RescheduleRequestByVolunteer from './rescheduleRequestByVolunteer';
import ScheduledEvent from './scheduledEvent';
import CancelledEvent from './cancelledEvent';
import { getUrlParam } from '../../utils/url';
import CompletedEvent from './completedEvent';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import { useNavigate } from 'react-router-dom';
import { selectSessionTitle } from '../../apiHooks/sessionTitle';
import { useSelector } from 'react-redux';
import useSelectProfileData from '../../hooks/useSelectProfileData';

type Props = {
  event: any;
  setDisplayUpcomingEventDetails?: any;
};

const ManageCallPage = ({ event, setDisplayUpcomingEventDetails }: Props) => {
  const { volunteerProfile, hasFinishedLoadingVolunteerProfile } =
    useSelectProfileData();
  if (
    hasFinishedLoadingVolunteerProfile &&
    volunteerProfile?.partnerInfo?.name !== undefined &&
    volunteerProfile?.partnerInfo.white_label
  )
    event.partner_name = volunteerProfile?.partnerInfo?.name;
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];
  const session_title = useSelector(selectSessionTitle);
  let navigate = useNavigate();
  event.session_title = session_title;
  const openMessagePopup = getUrlParam('messages') as string;
  const [change, setChange] = useState(false);
  useEffect(() => {
    if (change === true) window.location.reload();
  }, [change]);
  const profileData = {
    name: event.organisation?.first_name,
    picture: event.organisation?.profile_picture,
    companyName: event.organisation?.name,
    currentRole: event.organisation?.current_role,
    showPresentedBy: false,
  };

  let eventType = undefined;
  if (event.status === 'cancelled') eventType = 'cancelled';
  else if (event.status === 'complete') eventType = 'completed';
  else if (
    event.reschedule_status === 'pending' &&
    event.reschedule_requested_by === 'organisation'
  )
    eventType = 'rescheduleRequestByOrganisation';
  else if (
    event.reschedule_status === 'pending' &&
    event.reschedule_requested_by === 'volunteer'
  )
    eventType = 'rescheduleRequestByVolunteer';
  else if (event.status === 'scheduled') eventType = 'scheduledEvent';
  var skillLevel = 'I know nothing';
  if (event.interest_expertise === 'i_know_a_lot')
    skillLevel = 'I know a lot, just need a help with some specifics';
  else if (event.interest_expertise === 'i_know_some')
    skillLevel = 'I know some';

  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [messagesRead, setMessagesRead] = useState(false);
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const [isMenteeModalOpen, setOpenMenteeModal] = React.useState(false);

  const [rescheduleModalOpen, setIsRescheduleModalOpen] = React.useState(false);

  useEffect(() => {
    if (toggles['NEW_MESSAGING_UI'] && !showMessagingUI && openMessagePopup) {
      setMessagesRead(true);
      setOpenMessagesModal(true);
    } else if (
      toggles['NEW_MESSAGING_UI'] &&
      showMessagingUI &&
      openMessagePopup
    ) {
      navigate(`/messaging-centre?id=${event?.organisation?.id}`);
    }
  }, [toggles, showMessagingUI, openMessagePopup, navigate, event]);

  const dateFormatted = formatDate(event.start_time, event.timeZone);

  const formattedTime = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };
  if (openMessagePopup) return null;
  return (
    <div>
      <div className="organisation-information-container">
        {setDisplayUpcomingEventDetails ? (
          <div
            className="header-text-button"
            onClick={() => setDisplayUpcomingEventDetails(null)}
          >
            <ArrowCircleLeftIcon sx={{ color: '#583372' }} />
            <Button
              variant="text"
              sx={{
                fontFamily: 'Gilroy',
                fontSize: '14px',
                fontWeight: 'bold',
                textAlign: 'center',
                textTransform: 'none',
                color: '#583372',
                paddingLeft: '2px',
              }}
              onClick={() => setDisplayUpcomingEventDetails(null)}
            >
              Go back
            </Button>
          </div>
        ) : null}
        {eventType === 'rescheduleRequestByOrganisation' ? (
          <RescheduleRequestByOrganisation
            event={event}
            messagesRead={messagesRead}
            setOpenMenteeModal={setOpenMenteeModal}
            setOpenMessagesModal={setOpenMessagesModal}
            setRescheduleModal={setRescheduleModal}
          />
        ) : null}
        {eventType === 'rescheduleRequestByVolunteer' ? (
          <RescheduleRequestByVolunteer
            event={event}
            messagesRead={messagesRead}
            setOpenMenteeModal={setOpenMenteeModal}
            setOpenMessagesModal={setOpenMessagesModal}
            setRescheduleModal={setRescheduleModal}
          />
        ) : null}
        {eventType === 'scheduledEvent' ? (
          <ScheduledEvent
            event={event}
            messagesRead={messagesRead}
            setOpenMenteeModal={setOpenMenteeModal}
            setOpenMessagesModal={setOpenMessagesModal}
            setRescheduleModal={setRescheduleModal}
          />
        ) : null}
        {eventType === 'cancelled' ? (
          <CancelledEvent
            event={event}
            messagesRead={messagesRead}
            setOpenMenteeModal={setOpenMenteeModal}
            setOpenMessagesModal={setOpenMessagesModal}
          />
        ) : null}
        {eventType === 'completed' ? (
          <CompletedEvent
            event={event}
            messagesRead={messagesRead}
            setOpenMenteeModal={setOpenMenteeModal}
            setOpenMessagesModal={setOpenMessagesModal}
          />
        ) : null}
        <div className="organisation-information-paper">
          <div className="organisation-information-category-title">
            Requested by
          </div>
          <div className="organisation-information-photo">
            <div
              className="profile-photo-card"
              style={{
                cursor: 'pointer',
                backgroundImage: event.organisation?.profile_picture
                  ? `url(${event.organisation.profile_picture})`
                  : 'url(/avatar.png)',
              }}
              onClick={() => setOpenMenteeModal(true)}
            ></div>
            <div className="manage-call-header-info">
              <div className="organisation-information-category-header ">
                {event.organisation?.first_name}
              </div>
              <div className="manage-call-organisation">
                {' '}
                {event.organisation?.name}
              </div>
            </div>
          </div>
          <div className="organisation-information-category-title">
            Needs help in
          </div>
          <div className="organisation-information-category-subtext">
            {event.top_priority.name || event.top_priority_name}
          </div>
          <div className="organisation-information-category-title">
            {event.organisation?.first_name}'s request
          </div>
          <div className="organisation-information-category-subtext">
            {event.help_needed}
          </div>
          <div className="organisation-information-category-title">
            Current skill level
          </div>
          <div className="organisation-information-category-subtext">
            {skillLevel}
          </div>
        </div>
        {openMessagesModal ? (
          <MessagesModal
            boostCallEvent={event as BoostCallEvent}
            onClose={() => setOpenMessagesModal(false)}
            onReadMessages={() => setMessagesRead(true)}
            openMessagesModal={openMessagesModal}
          />
        ) : null}
        {isMenteeModalOpen ? (
          <MenteeDetailsModal
            isOpen={isMenteeModalOpen}
            event={event}
            setIsModalOpen={setOpenMenteeModal}
          />
        ) : null}
        {rescheduleModalOpen ? (
          <RescheduleDialog
            isOpen={rescheduleModalOpen}
            event={event}
            setIsRescheduleModalOpen={setIsRescheduleModalOpen}
          />
        ) : null}

        <RescheduleUpcomingCard
          profileData={profileData}
          timeZone={event.timeZone}
          id={event.id}
          time={formattedTime}
          setChange={setChange}
          setRescheduleModal={setRescheduleModal}
          rescheduleModal={rescheduleModal}
        />
      </div>
    </div>
  );
};

export default memo(ManageCallPage);
