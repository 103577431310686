import React, { useState, useEffect } from 'react';
import axios from '../../../api';
import api from '../../../api';
import { getUrlParam } from '../../../utils/url';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import EditInput from '../ProfileUtils/EditInput';
import ImageUploader from 'react-images-upload';
// import RefreshFromLinkedIn from '../ProfileUtils/RefreshFromLinkedIn';
import 'react-toastify/dist/ReactToastify.css';
import { VolunteerProfile, Experiences } from '../types';
import '../style/profile.scss';
import '../style/index.scss';

import { genderMap } from '../../../utils/constants';
import EditPastExperienceForm from '../Forms/EditPastExperienceForm';
import PastExperiences from '../ProfileUtils/PastExperiences';
import EditIcon from '@mui/icons-material/Edit';
import {
  CustomProfileCancelButton,
  CustomProfileSaveButton,
} from '../../../styling/buttons';
import { useSelector } from 'react-redux';
import { selectPartnerProfile } from '../../../apiHooks/partnerProfile';

type Props = {
  data: VolunteerProfile | null | undefined;
};

let updatedProfile: VolunteerProfile = {};
const Profile = ({ data }: Props) => {
  let navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [volunteer, setVolunteer] = useState(data);
  const [pastExperience, setPastExperience] = useState<Experiences[]>(
    volunteer?.employment_history || []
  );
  const [profilePhoto, setProfilePhoto] = useState(volunteer?.profile_picture);
  const [openProfileEdit, setOpenProfileEdit] = useState(false);
  const code = getUrlParam('code');
  const partner = useSelector(selectPartnerProfile);
  const [savedPressed, setSavedPressed] = useState<number>(0);
  useEffect(() => {
    const getLinkedIn = async (code: any) => {
      try {
        let sendObject: { code: any; partner_id?: number; source: string } = {
          code,
          source: 'preferences',
        };

        if (partner) {
          sendObject = { code, partner_id: partner.id, source: 'preferences' };
        }

        await api.post(`api/core/linkedin_callback/`, sendObject);
      } catch (e) {
        console.error(e);
      }
      const liProfile: { data: VolunteerProfile } = await api.get(
        `api/core/linkedinprofiles/me/`
      );
      setValue(liProfile.data.summary, 'summary');
      setValue(liProfile.data.first_name, 'first_name');
      setValue(liProfile.data.last_name, 'last_name');
      setValue(liProfile.data.current_role, 'current_role');
      setValue(liProfile.data.employer, 'employer');

      var newProfile = data;

      if (newProfile) {
        newProfile.employer = liProfile.data.employer;
        newProfile.first_name = liProfile.data.first_name;
        newProfile.last_name = liProfile.data.last_name;
        newProfile.summary = liProfile.data.summary;
        newProfile.current_role = liProfile.data.current_role;
        newProfile.employment_history = liProfile.data.employment_history;
      }
      setVolunteer(newProfile);

      setOpenProfileEdit(true);
      if (liProfile.data.employment_history)
        setPastExperience(liProfile.data.employment_history);
    };
    if (code) {
      getLinkedIn(code);
    }
  }, [code, data, partner]);

  useEffect(() => {
    const getAdditionalData = async () => {
      let newData = {
        ethnicityName: '',
        countryName: '',
      };
      try {
        let ethnicity = await axios
          .get(`api/ethnicity/ethnicity/?limit=1000`, {})
          .then((response) => {
            let ethnicity = response.data.results.filter(
              (ethnicity: { id: number | undefined }) =>
                ethnicity.id === volunteer?.ethnicity
            )[0].name;
            return ethnicity;
          });
        newData.ethnicityName = ethnicity;
      } catch (e) {
        console.error(e);
      }
      try {
        let country = await axios
          .get(`api/location/countries/?limit=1000`, {})
          .then((response) => {
            let country = response.data.results.filter(
              (country: { code: string | undefined }) =>
                country.code === volunteer?.country
            )[0].name;
            return country;
          });
        newData.countryName = country;
        setVolunteer({ ...data, ...newData });
      } catch (e) {
        console.error(e);
      }
    };
    getAdditionalData();
  }, [
    volunteer?.last_name,
    data,
    volunteer?.country,
    volunteer?.employment_history,
    volunteer?.ethnicity,
  ]);

  const handleSaveClick = async (updatedProfile: {}) => {
    setSavedPressed(savedPressed + 1);

    try {
      await api.patch(`api/volunteer/volunteers/me/`, updatedProfile);
    } catch (e) {
      console.error(e);
    }

    try {
      await api.patch(`api/core/users/me/`, updatedProfile);
    } catch (e) {
      console.error(e);
    }
    try {
      await api.patch(`api/location/locations/me/`, updatedProfile);
    } catch (e) {
      console.error(e);
    }

    navigate('/dashboard/account');
    window.location.reload();
  };

  const setValue = (value: any, input: any) => {
    updatedProfile = Object.assign(updatedProfile, { [`${input}`]: value });
  };

  const updateProfilePhoto = async (files: (string | Blob)[]) => {
    const formData = new FormData();
    formData.append('profile_picture', files[0]);
    try {
      const profile = await api.patch(`api/core/users/me/`, formData);
      setProfilePhoto(profile.data.profile_picture);
    } catch (e) {
      console.error(e);
    }
  };

  const onDrop = (files: any) => {
    updateProfilePhoto(files);
  };

  const volunteerJob = volunteer?.current_role + ' at ' + volunteer?.employer;
  return (
    <div className="profile_form">
      <div className="profile_summary__container">
        <div className="profile-picture__container">
          <div
            className="profile-picture"
            style={{
              backgroundImage: profilePhoto
                ? `url(${profilePhoto})`
                : 'url(/avatar.png)',
            }}
          ></div>
          <ImageUploader
            style={{ backgroundColor: 'transparent' }}
            withIcon={false}
            withPreview={false}
            buttonText="Change Photo"
            label=""
            singleImage={true}
            onChange={onDrop}
            imgExtension={[
              '.jpg',
              '.gif',
              '.png',
              '.gif',
              '.svg',
              '.peg',
              '.jpeg',
            ]}
            maxFileSize={2248576}
            fileSizeError="File size is too big, please keep it below 2 MB"
          />
        </div>
        <div className="summary-text">
          <div className="volunteer-name">
            {volunteer?.first_name} {volunteer?.last_name}
          </div>
          <div className="volunteer-job">{volunteerJob}</div>
          <div className="volunteer-join-date">
            volunteer since {moment(volunteer?.created).format('MMMM Do, YYYY')}
          </div>

          {/* <RefreshFromLinkedIn partnerId={partner?.id} /> */}
        </div>
      </div>

      <EditInput
        inputField="name"
        name={'Name'}
        input={volunteer?.first_name + ' ' + volunteer?.last_name}
        value={{
          first_name: volunteer?.first_name,
          last_name: volunteer?.last_name,
        }}
        setValue={setValue}
        openEdit={openProfileEdit}
      />

      <EditInput
        inputField="gender"
        name={'Gender Identity'}
        input={volunteer?.gender ? genderMap[volunteer.gender] : null}
        value={volunteer?.gender}
        setValue={setValue}
        openEdit={openProfileEdit}
      />

      <EditInput
        inputField="ethnicity"
        name={'Ethnicity'}
        input={volunteer?.ethnicityName}
        value={volunteer?.ethnicity}
        setValue={setValue}
        openEdit={openProfileEdit}
      />

      <EditInput
        inputField="location"
        name={'Location'}
        input={volunteer?.countryName + ', ' + volunteer?.postcode}
        value={{ country: volunteer?.country, postcode: volunteer?.postcode }}
        setValue={setValue}
        openEdit={openProfileEdit}
      />

      <EditInput
        inputField="timezone"
        name={'Timezone'}
        input={volunteer?.timezone}
        value={volunteer?.timezone}
        setValue={setValue}
        openEdit={openProfileEdit}
      />

      <EditInput
        inputField="companyRole"
        name={'Company and Role'}
        input={volunteer?.current_role + ' at ' + volunteer?.employer}
        value={{
          role: volunteer?.current_role,
          company: volunteer?.employer,
          partner: volunteer?.partner,
        }}
        setValue={setValue}
        openEdit={openProfileEdit}
      />

      <EditInput
        inputField="introduction"
        name={'Your introduction'}
        input={
          volunteer?.summary !== undefined
            ? `${volunteer?.summary}`
            : 'No introduction available'
        }
        value={volunteer?.summary}
        setValue={setValue}
        openEdit={openProfileEdit}
      />
      <div className="profile-input__container">
        <div className="profile-input_name">Past experience</div>

        {!edit ? (
          <EditIcon
            sx={{ fontSize: 'small', marginTop: '24px' }}
            onClick={() => setEdit(true)}
          />
        ) : null}
      </div>
      {!edit ? (
        <PastExperiences data={pastExperience} />
      ) : (
        <EditPastExperienceForm
          pastExperience={pastExperience}
          savedPressed={savedPressed}
        />
      )}

      <div className="update-profile-buttons">
        <CustomProfileSaveButton
          variant="contained"
          onClick={() => handleSaveClick(updatedProfile)}
        >
          Save
        </CustomProfileSaveButton>
        <CustomProfileCancelButton
          variant="outlined"
          onClick={() => {
            navigate('/dashboard/account');
            window.location.reload();
          }}
        >
          Cancel
        </CustomProfileCancelButton>
      </div>
    </div>
  );
};

export default Profile;
