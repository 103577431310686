import api from '../api';
import { getErrorMessageFromResponse } from '../utils/error';
import { NpsReason } from '../types';

type sendFollowUpRequestProps = {
  postData: {
    score: { id?: number };
    is_follow_up_wanted: boolean;
    follow_up_text: string;
    interests: number[];
  };
  onSuccess: () => void;
  handleErrorMessage: (arg: string) => void;
};

type NpsReasonsResponse = {
  results: NpsReason[];
};

export const sendFollowUpRequest = async ({
  postData,
  onSuccess,
  handleErrorMessage,
}: sendFollowUpRequestProps) => {
  try {
    const response = await api.post(
      `api/netpromoterscore/followuprequest/`,
      postData
    );
    onSuccess();
    if (response) {
      console.log('response:', response);
      return true;
    }
  } catch (err) {
    const errorMessage = getErrorMessageFromResponse(err);
    handleErrorMessage(errorMessage);
    return false;
  }
};

export const getNpsReasons = async () => {
  const reasonsResponse = (
    await api.get<NpsReasonsResponse>(`api/netpromoterscore/reason/?limit=100`)
  ).data.results;
  return reasonsResponse
    .sort((a: NpsReason, b: NpsReason) => a.id - b.id)
    .filter((r: NpsReason) => !!r.show_to_volunteer);
};
