import React from 'react';
import ToolTip from '../../atoms/ToolTip';
import './input-with-label.scss';
import '../general.scss';
import { IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

interface Props extends React.HTMLProps<HTMLInputElement> {
  label?: string | undefined;
  placeHolder?: string | undefined;
  onInput?: any;
  maxlength?: number;
  containerClassName?: string;
  required?: boolean;
  tooltipText?: string;
  muiTooltip?: {
    showMuiTooltip: boolean;
    clickMuiTooltip: () => void;
    closeMuiTooltip: () => void;
    muiTooltipText: string;
    muiTooltipId: string;
  };
  isMobile?: any;
}

const InputWithLabel = ({
  label,
  required,
  placeHolder,
  maxlength,
  tooltipText,
  onInput = () => {
    console.log('onInput');
  },
  containerClassName,
  muiTooltip,
  isMobile,
  ...props
}: Props) => {
  return (
    <div
      className={`input-with-label ${
        containerClassName ? containerClassName : ''
      }`}
    >
      {required
        ? label && (
            <label className="required-field-with-asterisk">
              {label}
              <div>*</div>
              {tooltipText ? (
                <ToolTip
                  text={tooltipText}
                  id="timezone-tooltip"
                  className="profile-create__tooltip"
                />
              ) : null}
              {muiTooltip ? (
                <Tooltip
                  open={muiTooltip.showMuiTooltip}
                  onClick={muiTooltip.clickMuiTooltip}
                  onClose={muiTooltip.closeMuiTooltip}
                  leaveTouchDelay={isMobile ? 5000 : 1500}
                  placement={isMobile ? 'top-start' : 'right'}
                  title={muiTooltip.muiTooltipText}
                  id={muiTooltip.muiTooltipId}
                >
                  <IconButton
                    sx={{
                      fontSize: 'small',
                      maxWidth: '20px',
                      marginLeft: '10px',
                    }}
                  >
                    <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </label>
          )
        : label && <label>{label}</label>}

      <input
        onInput={onInput}
        placeholder={placeHolder}
        maxLength={maxlength}
        {...props}
      />
    </div>
  );
};

export default InputWithLabel;
