import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {
  CustomFormControlLabel,
  CustomTypography,
} from '../../../styling/generalStyling';
import FormControl from '@mui/material/FormControl';

type Props = {
  setValue: (value: any, input: string) => void;
  value: boolean;
};

export default function EditMentoringCallForm({ value, setValue }: Props) {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="mentoring-radio-buttons-group-label"
        defaultValue={value}
        name="radio-buttons-group"
        sx={{
          '& .Mui-checked': {
            color: '#7FFFD4',
          },
        }}
      >
        <CustomFormControlLabel
          value="true"
          onClick={() => setValue(true, 'call_request_emails')}
          control={<Radio />}
          label={
            <CustomTypography>
              I want to receive mentoring call requests
            </CustomTypography>
          }
        />
        <CustomFormControlLabel
          value="false"
          onClick={() => setValue(false, 'call_request_emails')}
          control={<Radio />}
          label={
            <CustomTypography>
              I do not want to receive mentoring call requests
            </CustomTypography>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}
