import React, { useState, useEffect } from 'react';
import api from '../../../api';
import axios from '../../../api';

import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import { VolunteerPreferences } from '../types';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import EditLocationForm from '../Forms/EditLocationForm';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  CustomProfileCancelButton,
  CustomProfileSaveButton,
} from '../../../styling/buttons';
import {
  CustomFormControlLabel,
  CustomFormLabel,
  CustomTypography,
} from '../../../styling/generalStyling';

type Country = {
  code: string;
  name: string;
};
export const getCountry = (countryCode: string, countryList: Country[]) => {
  const result = countryList.filter((country) => country.code === countryCode);
  return result[0];
};
type Props = {
  data: VolunteerPreferences;
};

const Mentee = ({ data }: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [volunteerPreferences] = useState(data);
  const [mentorCountries, setMentorCountries] = useState(
    volunteerPreferences ? volunteerPreferences.mentor_countries : []
  );
  const [preferCharities, setPreferCharities] = useState(
    volunteerPreferences ? volunteerPreferences.prefer_charities : false
  );
  const [preferBusinesses, setPreferBusinesses] = useState(
    volunteerPreferences ? volunteerPreferences.prefer_businesses : false
  );
  const [preferStudents, setPreferStudents] = useState(
    volunteerPreferences ? volunteerPreferences.prefer_students : false
  );
  const [preferFreelancers, setPreferFreelancers] = useState(
    volunteerPreferences ? volunteerPreferences.prefer_freelancers : false
  );
  const [preferHealthServices, setPreferHealthServices] = useState(
    volunteerPreferences ? volunteerPreferences.prefer_health_services : false
  );
  const [preferEducators, setPreferEducators] = useState(
    volunteerPreferences ? volunteerPreferences.prefer_educators : false
  );
  const [preferUnemployedFurloughed, setPreferUnemployedFurloughed] = useState(
    volunteerPreferences
      ? volunteerPreferences.prefer_unemployed_furloughed
      : false
  );
  const [preferSocialEnterprise, setPreferSocialEnterprise] = useState(
    volunteerPreferences ? volunteerPreferences.prefer_social_enterprise : false
  );
  const [preferWomen, setPreferWomen] = useState(
    volunteerPreferences ? volunteerPreferences.prefer_women : false
  );
  const [mentoringPreferenceOneOff, setMentoringPreferenceOneOff] = useState(
    volunteerPreferences ? volunteerPreferences.one_off_mentoring : true
  );
  const [mentoringPreferenceOngoing, setMentoringPreferenceOngoing] = useState(
    volunteerPreferences ? volunteerPreferences.ongoing_mentoring : false
  );
  const [radioValue, setRadioValue] = React.useState<string>(
    mentorCountries && mentorCountries.length === 249
      ? 'anyone'
      : 'countrySpecific'
  );
  const [selectedCountries, setSelectedCountries] = useState<Country[]>([]);

  const getCountriesList = () => {
    return axios
      .get(`api/location/countries/?limit=1000`, {})
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  };
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip3, setShowTooltip3] = useState(false);
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const response = await getCountriesList();
      setCountryList(response.data.results);
      var result: any = [];
      if (mentorCountries) {
        result = mentorCountries
          .map((country) => getCountry(country, response.data.results))
          .filter((countryObj) => countryObj !== null);
      }
      setSelectedCountries(result);
    };
    fetchData();
  }, [mentorCountries]);

  const handleSaveClick = async () => {
    const mentorAllCountries = countryList.map((country) => country.code);
    try {
      await api.patch(`api/volunteer/volunteers/me/`, {
        prefer_charities: preferCharities,
        prefer_businesses: preferBusinesses,
        prefer_students: preferStudents,
        prefer_freelancers: preferFreelancers,
        prefer_health_services: preferHealthServices,
        prefer_educators: preferEducators,
        prefer_unemployed_furloughed: preferUnemployedFurloughed,
        prefer_social_enterprise: preferSocialEnterprise,
        prefer_women: preferWomen,
        one_off_mentoring: mentoringPreferenceOneOff,
        ongoing_mentoring: mentoringPreferenceOngoing,
        mentor_countries:
          radioValue === 'anyone' ? mentorAllCountries : mentorCountries,
      });
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };

  return (
    <div className="mentoring-preferences__container">
      <div>
        <div className="mentoring-preferences-title">
          Type of mentoring you wish to do
        </div>
        <FormControl>
          <FormGroup>
            <CustomFormControlLabel
              control={
                <Switch
                  onChange={() => {
                    if (!mentoringPreferenceOneOff === false) {
                      if (mentoringPreferenceOngoing) {
                        setMentoringPreferenceOneOff(
                          !mentoringPreferenceOneOff
                        );
                      }
                    } else {
                      setMentoringPreferenceOneOff(!mentoringPreferenceOneOff);
                    }
                  }}
                  checked={mentoringPreferenceOneOff}
                />
              }
              label={
                <CustomTypography>
                  One-off sessions{' '}
                  <Tooltip
                    open={showTooltip1}
                    onClick={() => setShowTooltip1(true)}
                    onClose={() => setShowTooltip1(false)}
                    leaveTouchDelay={isMobile ? 5000 : 1500}
                    placement={isMobile ? 'top-start' : 'right'}
                    title="You only need to commit to one 60 minute session with a particular mentee to give ideas, feedback, and answer questions. You can always meet again after the first session if you both want to, and you can meet with as many different mentees as you want."
                  >
                    <IconButton sx={{ fontSize: 'small' }}>
                      <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
                    </IconButton>
                  </Tooltip>
                </CustomTypography>
              }
            />
            <CustomFormControlLabel
              control={
                <Switch
                  onChange={() => {
                    if (!mentoringPreferenceOngoing === false) {
                      if (mentoringPreferenceOneOff) {
                        setMentoringPreferenceOngoing(
                          !mentoringPreferenceOngoing
                        );
                      }
                    } else {
                      setMentoringPreferenceOngoing(
                        !mentoringPreferenceOngoing
                      );
                    }
                  }}
                  checked={mentoringPreferenceOngoing}
                />
              }
              label={
                <CustomTypography>
                  Ongoing mentoring{' '}
                  <Tooltip
                    open={showTooltip2}
                    onClick={() => setShowTooltip2(true)}
                    onClose={() => setShowTooltip2(false)}
                    leaveTouchDelay={isMobile ? 5000 : 1500}
                    placement={isMobile ? 'top-start' : 'right'}
                    title="You are interested in having an ongoing relationship with a particular mentee to support them throughout their business journey. There is no specific requirement for how often you’d meet, but we suggest you plan to meet with them around once a month."
                  >
                    <IconButton sx={{ fontSize: 'small' }}>
                      <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
                    </IconButton>
                  </Tooltip>
                </CustomTypography>
              }
            />
          </FormGroup>
        </FormControl>
      </div>

      <div className="mentoring-preferences-title">
        Type of mentees you wish to mentor
      </div>
      <FormControl>
        <CustomFormLabel id="gender-radio-buttons-group-label">
          You will only receive requests from people working in the type of
          organisations you have selected.
        </CustomFormLabel>
        <FormGroup>
          <CustomFormControlLabel
            control={
              <Checkbox
                checked={preferBusinesses}
                onChange={() => {
                  setPreferBusinesses(!preferBusinesses);
                }}
                name={'owners or employed by businesses'}
                color="primary"
              />
            }
            label={
              <CustomTypography>
                owners or employed by businesses
              </CustomTypography>
            }
          />
          <CustomFormControlLabel
            control={
              <Checkbox
                checked={preferCharities}
                onChange={() => {
                  setPreferCharities(!preferCharities);
                }}
                name={'owners or employed by charities'}
                color="primary"
              />
            }
            label={
              <CustomTypography>
                owners or employed by charities
              </CustomTypography>
            }
          />
          <CustomFormControlLabel
            control={
              <Checkbox
                checked={preferSocialEnterprise}
                onChange={() => {
                  setPreferSocialEnterprise(!preferSocialEnterprise);
                }}
                name={'owners or employed by social enterprises'}
                color="primary"
              />
            }
            label={
              <CustomTypography>
                owners or employed by social enterprises
              </CustomTypography>
            }
          />
          <CustomFormControlLabel
            control={
              <Checkbox
                checked={preferEducators}
                onChange={() => {
                  setPreferEducators(!preferEducators);
                }}
                name={'owners or employed by educators'}
                color="primary"
              />
            }
            label={
              <CustomTypography>
                owners or employed by educators
              </CustomTypography>
            }
          />
          <CustomFormControlLabel
            control={
              <Checkbox
                checked={preferHealthServices}
                onChange={() => {
                  setPreferHealthServices(!preferHealthServices);
                }}
                name={'owners or employed by health services'}
                color="primary"
              />
            }
            label={
              <CustomTypography>
                owners or employed by health services
              </CustomTypography>
            }
          />

          <CustomFormControlLabel
            control={
              <Checkbox
                checked={preferStudents}
                onChange={() => {
                  setPreferStudents(!preferStudents);
                }}
                name={'who are students'}
                color="primary"
              />
            }
            label={<CustomTypography>who are students</CustomTypography>}
          />

          <CustomFormControlLabel
            control={
              <Checkbox
                checked={preferUnemployedFurloughed}
                onChange={() => {
                  setPreferUnemployedFurloughed(!preferUnemployedFurloughed);
                }}
                name={'who are unemployed or furloughed'}
                color="primary"
              />
            }
            label={
              <CustomTypography>
                who are unemployed or furloughed
              </CustomTypography>
            }
          />
          <CustomFormControlLabel
            control={
              <Checkbox
                checked={preferFreelancers}
                onChange={() => {
                  setPreferFreelancers(!preferFreelancers);
                }}
                name={'who are self-employed or freelancers'}
                color="primary"
              />
            }
            label={
              <CustomTypography>
                who are self-employed or freelancers
              </CustomTypography>
            }
          />
        </FormGroup>
      </FormControl>

      <div className="mentoring-preferences-title">
        Preference for gender of mentees
      </div>
      <FormControl>
        <CustomFormLabel id="gender-radio-buttons-group-label">
          <div>
            You may prioritise mentoring female mentees, or be open to mentor
            anyone.
            <div style={{ color: '#2f80ed' }}>
              Why should I prioritise mentoring female learners?
              <Tooltip
                open={showTooltip3}
                onClick={() => setShowTooltip3(true)}
                onClose={() => setShowTooltip3(false)}
                leaveTouchDelay={isMobile ? 5000 : 1500}
                placement={isMobile ? 'top-start' : 'right'}
                title="According to the British Business Bank (2020), female business owners of all ethnicities experience 67% lower median turnover than male entrepreneurs. Providing your preference to prioritise female learners is optional but recommended to bridge diversity gaps in the community, boost learner revenue and life prospects."
              >
                <IconButton sx={{ fontSize: 'small' }}>
                  <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </CustomFormLabel>
        <RadioGroup
          aria-labelledby="gender-radio-buttons-group-label"
          value={preferWomen ? 'women' : 'anyone'}
          name="radio-buttons-group"
          sx={{
            '& .Mui-checked': {
              color: '#7FFFD4',
            },
          }}
        >
          <CustomFormControlLabel
            value="anyone"
            onClick={() => setPreferWomen(false)}
            control={<Radio />}
            label={
              <CustomTypography>
                I prefer to mentor anyone without prioritizing specific gender
              </CustomTypography>
            }
          />
          <CustomFormControlLabel
            value="women"
            onClick={() => setPreferWomen(true)}
            control={<Radio />}
            label={
              <CustomTypography>
                I prefer to prioritise mentoring female learners
              </CustomTypography>
            }
          />
        </RadioGroup>
      </FormControl>

      <div className="mentoring-preferences-title">
        Preference for location of mentees
      </div>
      <CustomFormLabel id="gender-radio-buttons-group-label">
        You may choose to only mentor mentees from specific countries.
      </CustomFormLabel>
      <div
        style={{
          display: `${selectedCountries.length === 0 ? 'none' : 'block'}`,
        }}
      >
        {radioValue === 'countrySpecific' &&
        selectedCountries.length > 0 &&
        selectedCountries.length !== 249
          ? selectedCountries.map((country, index) => (
              <div key={index} className="mentoring-preferences-button">
                <Button
                  onClick={() => {
                    setSelectedCountries(
                      selectedCountries.filter(
                        (countrySelected) =>
                          countrySelected.name !== country.name
                      )
                    );
                    const sendMentorCountries = selectedCountries
                      .filter(
                        (countrySelected) =>
                          countrySelected.name !== country.name
                      )
                      .map((country) => country.code);
                    setMentorCountries(sendMentorCountries);
                  }}
                  sx={{
                    padding: '1px',
                    marginRight: '10px',
                    textTransform: 'none',
                    color: 'black',
                  }}
                  endIcon={<CloseIcon />}
                >
                  {country.name}
                </Button>
              </div>
            ))
          : null}
      </div>
      <EditLocationForm
        selectedCountries={selectedCountries}
        setSelectedCountries={setSelectedCountries}
        countryList={countryList}
        mentorCountries={mentorCountries}
        setMentorCountries={setMentorCountries}
        radioValue={radioValue}
        setRadioValue={setRadioValue}
      />

      <div className="update-preferences-buttons">
        <CustomProfileSaveButton
          variant="contained"
          onClick={() => handleSaveClick()}
        >
          Save
        </CustomProfileSaveButton>
        <CustomProfileCancelButton
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Cancel
        </CustomProfileCancelButton>
      </div>
    </div>
  );
};

export default Mentee;
