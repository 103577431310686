import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { PrivacyPreferences } from '../types';
import {
  CustomFormControlLabel,
  CustomFormLabel,
  CustomTypography,
} from '../../../styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
  value: PrivacyPreferences;
};

export default function EditPrivacyForm({ value, setValue }: Props) {
  const [values, setValues] = useState(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <FormControl component="fieldset" variant="standard">
      <CustomFormLabel>
        You can select what information you want to show to organisations.
      </CustomFormLabel>
      <FormGroup>
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={values.show_current_role}
              onChange={(event) => {
                handleChange(event);
                setValue(!values.show_current_role, 'show_current_role');
              }}
              name="show_current_role"
              color="primary"
            />
          }
          label={<CustomTypography>Current Job</CustomTypography>}
        />
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={values.show_employer}
              onChange={(event) => {
                handleChange(event);
                setValue(!values.show_employer, 'show_employer');
              }}
              name="show_employer"
              color="primary"
            />
          }
          label={<CustomTypography>Company</CustomTypography>}
        />
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={values.show_last_name}
              onChange={(event) => {
                handleChange(event);
                setValue(!values.show_last_name, 'show_last_name');
              }}
              name="show_last_name"
              color="primary"
            />
          }
          label={<CustomTypography>Last Name</CustomTypography>}
        />
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={values.show_employment_history}
              onChange={(event) => {
                handleChange(event);
                setValue(
                  !values.show_employment_history,
                  'show_employment_history'
                );
              }}
              name="show_employment_history"
              color="primary"
            />
          }
          label={<CustomTypography>Past Experience</CustomTypography>}
        />
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={values.show_linkedin_profile}
              onChange={(event) => {
                handleChange(event);
                setValue(
                  !values.show_linkedin_profile,
                  'show_linkedin_profile'
                );
              }}
              name="show_linkedin_profile"
              color="primary"
            />
          }
          label={<CustomTypography>LinkedIn Profile</CustomTypography>}
        />
      </FormGroup>
    </FormControl>
  );
}
